import {POST, setHeader} from '../../js/requests.js'

const URL = "users/"

export default {
  namespaced: true,
  actions: {
    loginUser(context, user){
      const url = URL + "login"
      const data = {
        name: user.login,
        password: user.password
      }
      return POST(url, data)
          .then(response => {
            localStorage.token = response.data.token
            setHeader("Authorization", 'Bearer ' + response.data.token)
          })
    }
  },
  mutations: {
    initializeUser(){
      setHeader("Authorization", 'Bearer ' +  localStorage.token)
    }
  },
  state: {
  },
  getters: {
  }
}
