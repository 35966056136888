<template>
  <v-card>
    <v-card-title>
      <v-text-field
          label="Название"
          v-model="block.name"
          outlined
      ></v-text-field>
    </v-card-title>

    <v-card-text style="white-space: pre-line">
      <v-textarea
          label="Текст"
          v-model="block.text"
          rows="15"
          outlined
      ></v-textarea>
      <v-text-field
          label="Теги"
          v-model="block.tags"
          outlined
      ></v-text-field>

      <h2>Связи</h2>
      <h3 class="my-4">Связь к блоку</h3>
      <relations
          :block="block"
          :filtered_relations="relations_from"
          direction="from"
          @update="load"
      ></relations>
      <h3 class="my-4">Связь от блока</h3>
      <relations
          :block="block"
          :filtered_relations="relations_to"
          direction="to"
          @update="load"
      ></relations>
      <h2 class="my-3">Приложения</h2>
      <div
          v-for="attachment in block.attachment"
          :key="attachment.id"
          class="attachment-item"
      >
        {{attachment.vk_attachment}}
        <v-spacer></v-spacer>
        <v-btn @click="remove_attachment(attachment)" icon color="error" small><v-icon small>mdi-close</v-icon></v-btn>
      </div>
      <v-text-field
          label="Добавить приложение"
          v-model="attachment_text"
          @keydown.enter="create_attachment"
          style="display: inline-block"
      ></v-text-field>

    </v-card-text>

    <v-card-actions>
      <v-btn
          right
          @click="save"
          dark
          color="green"
      >Сохранить</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Relations from "./Relations";

export default {
  components: {
    Relations
  },
  data(){
    return{
      loaded: false,
      attachment_text: ''
    }
  },

  mounted(){
    this.load()
  },

  methods:{
    ...mapActions("blocks", [
      "fetch_blocks",
      "update_block",
      "add_attachment",
      "delete_attachment"
    ]),

    ...mapActions("relations", [
      "create_relation",
      "delete_relation",
      "fetch_relations"
    ]),

    load(){
      this.loaded = false
      this.fetch_blocks()
      this.fetch_relations()
      this.loaded = true
    },

    create_attachment(){
      if (this.attachment_text !== '')
        this.add_attachment(this.attachment_form)
        .then(() => {
          this.attachment_text = ''
        })
    },

    remove_attachment(attachment){
      this.delete_attachment(attachment)
    },

    save(){
      this.update_block(this.block)
      this.load()
    }
  },

  computed:{
    ...mapGetters("blocks", [
      "block_by_id",
      "blocks"
    ]),

    ...mapGetters("relations", [
      "find_relation",
      "relations"
    ]),

    attachment_form(){
      return {
        block_id: this.block.id,
        vk_attachment: this.attachment_text,
      }
    },

    relations_from(){
      if (!this.block.relations) return []
      return this.block.relations.filter(relation => relation.block_id_from !== this.block.id)
    },

    relations_to(){
      if (!this.block.relations) return []
      return this.block.relations.filter(relation => relation.block_id_to !== this.block.id)
    },

    block(){
      return this.block_by_id(this.block_id)
    },

    block_id () {
      return this.$route.params.id
    }
  }
}
</script>

<style lang="scss">

.attachment-item {
  background-color: rgba(0, 0, 0, .05);
  border-radius: 5px 10px;
  padding: 10px;
  display: flex;
  max-width: 300px;
}

</style>
