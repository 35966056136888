import Vue from 'vue'
import Vuex from 'vuex'
import blocks from './modules/blocks'
import search from './modules/search'
import relations from './modules/relations'
import variables from './modules/variables'
import users from "./modules/users";

Vue.use(Vuex)

export const store = new Vuex.Store({
  namespaced: true,
  modules: {
    search,
    blocks,
    relations,
    variables,
    users
  }
})
