import {GET, POST, PUT, DELETE} from '../../js/requests.js'

const URL = "variables/"

export default {
  namespaced: true,
  actions: {
    fetch_variables({commit}){
      return GET(URL)
        .then(response => {
          commit("set_variables", response.data)
        })
    },

    create_variable({dispatch}, variable){
      const data = {
        name: variable.name,
        value: variable.value
      }
      return POST(URL, data)
        .then( () => {
          dispatch("fetch_variables")
        })
    },

    update_variable({dispatch}, variable){
      const data = {
        name: variable.name,
        value: variable.value
      }
      return PUT(URL + variable.id, data)
        .then( () => {
          dispatch("fetch_variables")
        })
    },

    delete_variable({dispatch}, variable){
      return DELETE(URL + variable.id)
        .then( () => {
          dispatch("fetch_variables")
        })
    }
  },
  mutations: {
    set_variables(state, variables){
      state.variables = variables
    }
  },
  state: {
    variables: []
  },
  getters: {
    variables(state){
      return state.variables.sort((a,b) => {
        if (a.name > b.name) return 1
        else if (a.name < b.name) return -1
        return 0
      })
    },

    find_variable: state => id => {
      return state.variables.find(variable => variable.id === id)
    }
  }
}
