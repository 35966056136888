<template>
  <div class="login fill-height">
    <v-card min-width="300">
      <v-form ref="form">
        <v-card-title>Админка Гутки</v-card-title>
        <v-card-text>
          <v-text-field
            label='Логин'
            v-model="user_form.login"
            :rules="rules"
          ></v-text-field>
          <v-text-field
            label="Пароль"
            type="password"
            v-model="user_form.password"
            @keydown.enter="submit"
            :rules="rules"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="submit">Авторизоваться</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
export default {
  methods: {
    ...mapActions("users", [
        "loginUser"
    ]),

    submit(){
      if (this.$refs.form.validate()){
        this.loginUser(this.user_form)
          .then(()=>{
            this.$router.push({name: 'Blocks'})
          })
      }
    }
  },
  data(){
    return{
      user_form: {
        login: '',
        password: ''
      },
      rules: [
          v => !!v || "Обязательное поле"
      ]
    }
  }
}
</script>

<style lang="scss">

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
