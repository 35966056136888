import {GET, POST, DELETE} from '../../js/requests.js'


const URL = "relations/"

export default {
  namespaced: true,
  actions: {
    fetch_relations({commit}){
      return GET(URL)
        .then(response => {
          commit("set_relations", response.data)
        })
    },

    delete_relation({dispatch}, relation_id){
      return DELETE(URL + relation_id)
        .then( () => {
          dispatch("fetch_relations")
        })
    },

    create_relation({dispatch}, relation){
      const data = {...relation}
      return POST(URL, data)
        .then(() => {
          dispatch("fetch_relations")
        })
    }
  },
  mutations: {
    set_relations(state, relations){
      state.relations = relations
    }
  },
  state: {
    relations: []
  },
  getters: {
    relations(state){
      return state.relations
    },

    find_relation: state => (from, to) => {
      return state.relations.find(rel => rel.block_id_to === to && rel.block_id_from === from) || {}
    }
  }
}
