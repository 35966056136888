import {GET, POST, PUT, DELETE} from '../../js/requests.js'

const URL = "blocks/"

export default {
  namespaced: true,
  actions: {
    add_attachment({commit}, attachment){
      const data = {...attachment}
      return POST(URL + 'attachment', data)
          .then(response => {
            commit('add_attachment', response.data)
          })
    },

    delete_attachment({commit}, attachment){
      return DELETE(URL + 'attachment/' + attachment.id)
          .then(() => {
            commit('remove_attachment', attachment)
          })
    },

    fetch_blocks({commit}){
      return GET(URL)
        .then(response => {
          commit("set_blocks", response.data)
        })
    },

    update_block({dispatch}, block){
      const data = {
        name: block.name,
        text: block.text,
        tags: block.tags
      }
      return PUT(URL + block.id, data)
        .then(() => {
          dispatch("fetch_blocks")
        })
    },

    create_block({dispatch}, block){
      return POST(URL, block)
        .then(() => {
          dispatch("fetch_blocks")
        })
    }
  },
  mutations: {
    set_blocks(state, blocks){
      state.blocks = blocks
    },

    add_attachment(state, attachment){
      const block = state.blocks.find(block => block.id === attachment.block_id)
      block.attachment.push(attachment)
    },

    remove_attachment(state, attachment){
      const block = state.blocks.find(block => block.id === attachment.block_id)
      block.attachment = block.attachment.filter(attch => attch.id !== attachment.id)
    }
  },
  state: {
    blocks: []
  },
  getters: {
    blocks(state){
      return state.blocks
    },

    block_by_id: state => id => {
      return state.blocks.find(a => {return  a.id === parseInt(id)}) || {}
    }
  }
}
