<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  mounted(){
  },
  data(){
    return{
    }
  }
}

</script>

<style lang="scss">


</style>
