import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'

import Variables from '../views/Variables/VariableList.vue'
import VariablePage from '../views/Variables/VariablePage.vue'

import Blocks from '../views/Blocks/BlockList.vue'
import BlockPage from '../views/Blocks/BlockPage.vue'

import Login from "../views/Login";


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        name: 'Variables',
        path: 'variables',
        component: Variables
      },
      {
        name: 'Variable',
        path: 'variables/:id',
        component: VariablePage,
      },

      {
        name: 'Blocks',
        path: 'blocks',
        component: Blocks
      },
      {
        name: 'Block',
        path: 'blocks/:id',
        component: BlockPage
      },
    ]
  },

  {
    path: '/login',
    component: Login
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
