import axios from 'axios'

const baseURL = process.env.VUE_APP_BASE_URL

export function GET(url, params={}){
    return axios.get(baseURL + url, {params: params})
}

export function POST(url, data){
    return axios.post(baseURL + url, data)
}

export function PUT(url, data){
    return axios.put(baseURL + url, data)
}

export function DELETE(url, data={}){
    return axios.delete(baseURL + url, data)
}

export function setHeader(key, value){
    axios.defaults.headers.common[key] = value;
}
