<template>
  <div>
    <div v-for="relation in filtered_relations" :key="relation.id" class="d-flex align-center">
      <router-link :to="{name: 'Block', params:{id: relation.block_id_to}}">
        <div class="relation">
          {{relation.name_from}} – {{relation.name_to}}
        </div>
      </router-link>
      <v-btn small icon @click="del_relation(relation)">
        <v-icon color="error" small>mdi-close</v-icon>
      </v-btn>
    </div>
    <v-menu bottom :close-on-content-click="false" v-model="menu">
      <template v-slot:activator="{on}">
        <v-btn class="my-3" outlined small v-on="on">
          Добавить связь
        </v-btn>
      </template>
      <v-card>
        <v-card-title>Добавить связь</v-card-title>
        <v-card-text>
          <v-autocomplete
            :items="filtered_blocks"
            item-text="name"
            return-object
            v-model="selection"
            dense
          ></v-autocomplete>
          <v-checkbox dense v-model="relation_form.reversible" label="Двунаправленная связь"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="success" @click="create">Создать</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  props: {
    filtered_relations: {
      type: Array,
      required: true
    },
    block: {
      type: Object,
      required: true
    },
    direction: {
      type: String,
      required: true
    }
  },
  data(){
    return{
      relation_form: {
        reversible: true
      },
      selection: null,
      menu: false
    }
  },

  methods: {
    ...mapActions('relations', [
        'delete_relation',
        'create_relation'
    ]),

    async create(){
      const data = {
        reversible: this.relation_form.reversible,
        block_id_to: this.direction === 'to' ? this.selection.id : this.block.id,
        block_id_from: this.direction === 'from' ? this.selection.id : this.block.id
      }
      await this.create_relation(data)
      this.menu = false
      this.$emit('update')
    },

    async del_relation(relation){
      await this.delete_relation(relation.id)
      this.$emit('update')
    },
  },

  computed: {
    ...mapGetters('relations', [
        'relations'
    ]),

    ...mapGetters('blocks', [
        'blocks'
    ]),

    relations_map(){
      if (this.direction === 'to') {
        return this.filtered_relations.map(relation => relation.block_id_to)
      } else return this.filtered_relations.map(relation => relation.block_id_from)
    },

    filtered_blocks(){
      return this.blocks.filter(block => {
        return !(this.relations_map.includes(block.id) || block.id === this.block.id);
      })
    }
  },
}
</script>

<style lang="scss">

.relation {
  padding: 5px 10px;
  border-radius: 15px;
  background-color: #a9b0ff;
  display: inline-block;
  color: white;
}

</style>
