<template>
  <div>
    <router-link
      v-for="block in blocks"
      :key="block.id"
      :to="{name: 'Block', params: {id: block.id}}"
    >
      <v-card class="my-4">
        <v-card-title>{{block.name}}</v-card-title>
        <v-card-text class="primary-title">
          {{block.tags}}
        </v-card-text>
      </v-card>
    </router-link>

    <v-dialog v-model="modal" max-width="500px">
      <template v-slot:activator="{on}">
        <v-btn class="my-4" dark color="green" v-on="on">Создать</v-btn>
      </template>
      <v-card>
        <v-card-title>Создать блок</v-card-title>
        <v-card-text>
          <v-text-field
            label="Название"
            v-model="new_block.name"
          ></v-text-field>
          <v-textarea
            label="Текст"
            v-model="new_block.text"
          ></v-textarea>
          <v-text-field
            label="Теги через запятую"
            v-model="new_block.tags"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="green" dark @click="create">Создать</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data(){
    return{
      new_block: {
        name: "",
        text: "",
        tags: ""
      },
      modal: false
    }
  },

  mounted(){
    this.load()
  },

  computed:{
    ...mapGetters("blocks", [
      "blocks"
    ])
  },

  methods:{
    ...mapActions("blocks", [
      "fetch_blocks",
      "create_block"
    ]),

    create(){
      this.create_block(this.new_block).then(() => {
          this.modal = false
        })
    },

    load(){
      this.fetch_blocks()
    }
  },


}
</script>

<style lang="scss">
</style>
