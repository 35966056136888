<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="variable.name"
        label="Название переменной"
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="variable.value"
        label="Значение переменной"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        dark
        color="green"
        @click="save"
      >Сохранить</v-btn>
      <v-btn
        class="error"
        @click="remove"
      >
        Удалить
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  data(){
    return{
      id: parseInt(this.$route.params.id),
      loaded: false
    }
  },

  mounted(){
    this.load()
  },

  methods:{
    ...mapActions("variables", [
      "fetch_variables",
      "update_variable",
        "delete_variable"
    ]),

    load(){
      this.fetch_variables()

    },

    save(){
      this.update_variable(this.variable)
      this.load()
    },

    remove(){
      this.delete_variable(this.variable)
      this.$router.push({name: 'Variables'})
    }
  },

  computed:{
    ...mapGetters("variables", [
      "find_variable",
      "variables"
    ]),

    variable(){
      return this.find_variable(this.id) || {}
    }
  }
}
</script>

<style lang="scss">
</style>
